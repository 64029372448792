import GATSBY_COMPILED_MDX from "/opt/build/repo/src/posts/why-you-need-a-map/index.mdx";
import React from 'react';
import styled from 'styled-components';
import {MDXProvider} from '@mdx-js/react';
import parseISO from 'date-fns/parseISO';
import {format} from 'date-fns-tz';
import {getImage} from 'gatsby-plugin-image';
import {Layout} from '../layout';
import {Text, UnorderedList, OrderedList, PageHeader, TableOfContents, ImageFullWidth, BlockQuote, AnimationFadeWrapper} from '../components';
import {device} from '../utils/devices';
import Seo from '../utils/seo';
var PostWrapper = styled.article.withConfig({
  displayName: "post__PostWrapper",
  componentId: "sc-16eynnf-0"
})(["position:relative;margin:auto;width:100%;background-color:var(--color-background);@media ", "{}@media ", "{max-width:1280px;}"], device.tablet, device.laptop);
var ContentWrapper = styled.article.withConfig({
  displayName: "post__ContentWrapper",
  componentId: "sc-16eynnf-1"
})(["display:grid;gap:0 24px;margin-inline:-24px;grid-template-rows:minmax(0,1fr);grid-template-columns:1fr repeat(4,minmax(0,100%)) 1fr;@media ", "{grid-template-columns:1fr repeat(8,minmax(0,100%)) 1fr;gap:0 32px;margin-inline:-32px;}@media ", "{padding-block-end:120px;gap:0 40px;margin-inline:-40px;grid-template-columns:1fr repeat(12,minmax(0,70px)) 1fr;}@media ", "{}@media ", "{}"], device.tablet, device.laptop, device.desktop, device.fourk);
var PostBody = styled.section.withConfig({
  displayName: "post__PostBody",
  componentId: "sc-16eynnf-2"
})(["> :nth-child(1){margin-block-start:0px;}grid-column:2 / span 4;@media ", "{grid-column:2 / span 8;}@media ", "{grid-column:2 / span 9;}"], device.tablet, device.laptop);
var PostBodyWithoutToC = styled.article.withConfig({
  displayName: "post__PostBodyWithoutToC",
  componentId: "sc-16eynnf-3"
})(["display:grid;gap:0 24px;flex:1;margin-inline:-24px;grid-template-columns:1fr repeat(4,minmax(0,100%)) 1fr;@media ", "{grid-template-columns:1fr repeat(8,minmax(0,100%)) 1fr;gap:0 32px;margin-inline:-32px;}@media ", "{gap:0 40px;margin-inline:-40px;grid-template-columns:1fr repeat(12,minmax(0,40px)) 1fr;}@media ", "{}@media ", "{}> :nth-child(1){margin-block-start:0px;}> *{grid-column:2 / span 4;@media ", "{grid-column:2 / span 8;}@media ", "{grid-column:2 / span 12;}}"], device.tablet, device.laptop, device.desktop, device.fourk, device.tablet, device.laptop);
var components = {
  h1: function h1(props) {
    return React.createElement(Text, Object.assign({
      heading3: true,
      as: "h2",
      mt: "4rem",
      mb: ".5rem"
    }, props));
  },
  h2: function h2(props) {
    return React.createElement(Text, Object.assign({
      heading4: true,
      as: "h3",
      mt: "3rem",
      mb: "1rem"
    }, props));
  },
  h3: function h3(props) {
    return React.createElement(Text, Object.assign({
      heading5: true,
      medium: true,
      as: "h4",
      mt: "2.25rem",
      mb: "1rem"
    }, props));
  },
  h4: function h4(props) {
    return React.createElement(Text, Object.assign({
      heading6: true,
      as: "h5",
      mt: "2rem",
      mb: "1rem"
    }, props));
  },
  h5: function h5(props) {
    return React.createElement(Text, Object.assign({
      overline: true,
      as: "h6",
      mt: "1.5rem",
      mb: "0.5rem"
    }, props));
  },
  p: function p(props) {
    return React.createElement(Text, Object.assign({
      body1: true,
      as: "p",
      mt: "1rem"
    }, props));
  },
  ul: function ul(props) {
    return React.createElement(UnorderedList, Object.assign({
      py: "24px"
    }, props));
  },
  ol: function ol(props) {
    return React.createElement(OrderedList, Object.assign({
      py: "24px"
    }, props));
  },
  ImageFullWidth: ImageFullWidth,
  BlockQuote: BlockQuote
};
var Post = function Post(_ref) {
  var location = _ref.location, data = _ref.data, tags = _ref.tags, children = _ref.children;
  var gotFeatureImage = getImage(data.mdx.frontmatter.featureImage);
  var parsedTime = parseISO(data.mdx.frontmatter.publishedOn);
  var formattedDate = format(parsedTime, 'dd. MMMM yyyy');
  return React.createElement(React.Fragment, null, React.createElement(Layout, {
    withHeader: true,
    withFooter: true
  }, React.createElement(PostWrapper, null, React.createElement(AnimationFadeWrapper, {
    duration: 0.4
  }, React.createElement(PageHeader, {
    title: data.mdx.frontmatter.title,
    featureImage: gotFeatureImage,
    featureImageAlt: data.mdx.frontmatter.featureImageAlt,
    formattedDate: formattedDate,
    area: "words",
    link: "words/newest",
    tags: data.mdx.frontmatter.tags
  })), React.createElement(AnimationFadeWrapper, {
    duration: 0.6
  }, data.mdx.frontmatter.includeToC ? React.createElement(ContentWrapper, null, React.createElement(PostBody, null, React.createElement(MDXProvider, {
    components: components
  }, children)), typeof data.mdx.tableOfContents.items === 'undefined' ? null : React.createElement(TableOfContents, {
    location: location,
    tableOfContents: data.mdx.tableOfContents
  })) : React.createElement(PostBodyWithoutToC, null, React.createElement(MDXProvider, {
    components: components
  }, children))))));
};
Post
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Post, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export var Head = function Head(_ref2) {
  var data = _ref2.data, location = _ref2.location;
  return React.createElement(Seo, {
    title: data.mdx.frontmatter.title,
    description: data.mdx.frontmatter.metaDescription,
    keywords: data.mdx.frontmatter.keywords,
    author: 'Jonathan Esbjug',
    slug: location.pathname,
    image: data.mdx.frontmatter.featureImage.relativePath
  });
};
var PostQuery = "1148385850";
